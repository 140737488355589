import { IFooter, IPageNavigation } from '@vfit/shared/models';

const organizeFooter = (footer: IFooter): IFooter => ({
  app: footer?.app,
  footerLinks:
    footer?.footerLinks?.map((e) => ({
      ...e,
      category: e?.links?.find((l) => !!l.category && !l.url)?.category || '',
    })) || [],
  copyright: footer?.copyright,
  socials: { ...footer?.socials, elements: footer?.socials?.elements },
  mobileLinks: footer?.mobileLinks,
  footerTopBanner: footer?.footerTopBanner,
});

export const useFooter = (cmsJson?: IPageNavigation | undefined) => {
  if (!cmsJson) {
    return {
      app: null,
      footerLinks: null,
      copyright: null,
      socials: null,
      mobileLinks: null,
      footerTopBanner: null,
    };
  }
  const footer = organizeFooter(cmsJson.footer);
  return { footer };
};
